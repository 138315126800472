<template>
  <div class="broadcast-edit-wizard">
    <div class="wizard-wrapper" :class="currentStep === 2 ? 'tab-fullscreen' : ''">
      <CreateStep v-show="currentStep === 1" ref="createStep" :broadcast="broadcast" :page-type="pageType"
        @done="(data) => onBroadcastChange(data)" @loading="loadingState" />
      <BuilderStep v-show="currentStep === 2" ref="builderStep" :broadcast="broadcast"
        @done="(data) => onBroadcastChange(data)" @loading="loadingState" />
      <div class="action-wrapper d-flex justify-content-between">
        <div class="actions-left flex-fill d-flex">
          <b-button v-if="currentStep === 1" variant="warning" type="button" @click="onExit">
            <span><i class="uil uil-arrow-left"></i> Back</span>
          </b-button>
          <b-button v-if="currentStep !== 1" variant="primary" type="button" @click="onPrev">
            <span><i class="uil uil-arrow-left"></i></span>
          </b-button>
          <div v-if="currentStep !== 1" class="ml-4 d-flex">
            <div style="width: 100px;">
              <NumberInput v-model="broadcast.options.width" margin-bottom="0px" unit-top="8px" :min="500" />
            </div>
            <div class="align-container">
              <div class="align-item" :class="{ active: broadcast.options.align === 'left' }"
                @click="chooseAlign('left')">
                <i class="uil uil-align-left"></i>
              </div>
              <div class="align-item" :class="{ active: broadcast.options.align === 'center' }"
                @click="chooseAlign('center')">
                <i class="uil uil-align-center"></i>
              </div>
              <div class="align-item" :class="{ active: broadcast.options.align === 'right' }"
                @click="chooseAlign('right')">
                <i class="uil uil-align-right"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="actions-right">
          <div v-if="currentStep !== totalSteps" class="d-block">
            <b-button variant="primary" type="button" @click="onNext">
              <span><i class="uil uil-arrow-right"></i> Next</span>
            </b-button>
          </div>
          <div v-if="currentStep === totalSteps" class="btn-group">
            <b-button v-if="broadcastEditable" variant="primary" type="button" @click="onClickTest">
              <b-spinner v-if="loadingSend" small />
              <span v-else><i class="uil uil-text"></i> Send Test Message</span>
            </b-button>

            <b-button v-if="broadcastEditable" variant="primary" type="button" @click="handleSaveAsDraft">
              <b-spinner v-if="loadingSend" small />
              <span v-else><i class="uil uil-document"></i> Save As Draft</span>
            </b-button>
            <b-button v-if="broadcastEditable" variant="primary" :disabled="loadingSend" type="button" @click="onSaveAndExit">
              <b-spinner v-if="loadingSend" small />
              <span v-else><i class="uil uil-file-upload"></i> Save & Exit</span>
            </b-button>
            <b-button variant="primary" :disabled="loadingSend" type="button" @click="onExit">
              <span><i class="uil uil-exit"></i> Exit</span>
            </b-button>
            <b-button v-if="broadcastEditable" variant="primary" :disabled="loadingSend" type="button" @click="onSendBroadcast">
              <b-spinner v-if="loadingSend" small />
              <span v-else><i class="uil uil-upload-alt"></i> Send</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="isOpenModalConfirm" title="Confirmation">
      <p>When sending out a broadcast emails, it is important that you send these messages
        during the day between <b class="text-primary">8am</b> and <b class="text-primary">9pm</b> during the recipients
        timezone. <br />If you aren't aware of their time zone, we suggest sending it in the middle of the day.</p>
      <p>Click the button below to confirm you want to send or schedule this messages at this time.</p>
      <template v-slot:modal-footer>
        <b-button variant="light" class="mr-2" @click="isOpenModalConfirm = false">Close</b-button>
        <b-button :disabled="loadingSend" variant="primary" @click="sendBroadcast">
          <b-spinner v-if="loadingSend" class="align-middle" small></b-spinner>
          <span v-else>{{ confirmLabel }}</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="isOpenModalTest" title="Test Broadcast">
      <p>Please Input Email Address to Send Test Broadcast.</p>
      <TextInput v-model="testNumber" name="testEmail" rules="required|email" label="Email" />
      <template v-slot:modal-footer>
        <b-button variant="light" class="mr-2" @click="isOpenModalTest = false">Close</b-button>
        <b-button :disabled="loadingSend || !testNumber" variant="primary" @click="handleTest">
          <b-spinner v-if="loadingSend" class="align-middle" small></b-spinner>
          <span v-else>Send</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import CreateStep from './Components/CreateStep'
import BuilderStep from './Components/BuilderStep'
// import PreviewStep from './Components/PreviewStep'
import NumberInput from '../Coupons/Components/NumberInput.vue'

export default {
  components: {
    CreateStep,
    BuilderStep,
    NumberInput
  },

  props: {
    broadcast: {
      type: [Object],
      default: null,
    },
    pageType: {
      type: String,
      default: 'create',
    }
  },

  data() {
    return {
      isOpenModalConfirm: false,
      isOpenModalTest: false,
      testNumber: null,
      loadingSend: false,
      currentStep: 1,
      totalSteps: 2,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    confirmLabel() {
      return this.broadcast.send_type === "now" ? "Send Now" : "Schedule";
    },

    broadcastEditable() {
      return !this.broadcast.status || ['scheduled', 'draft'].includes(this.broadcast.status)
    },
  },

  mounted() {
    this.constBroadcast = JSON.parse(JSON.stringify(this.broadcast))
    if (this.$route.query.step === 'builder') {
      this.currentStep = 2
    }
  },

  methods: {
    loadingState(loading) {
      this.loadingSend = loading
    },

    async onNext() {
      let elem = this.$refs.createStep
      switch (this.currentStep) {
        case 1:
          elem = this.$refs.createStep
          break;
        case 2:
          elem = this.$refs.builderStep
          break;
        default:
          break;
      }
      if (elem) {
        let res = await elem.validateStep()
        if (res && this.currentStep < this.totalSteps) {
          this.currentStep++
          this.$nextTick(() => {
            if (this.currentStep === 2 && this.broadcast.id) {
              this.$router.replace({ name: 'business.broadcasts.edit', params: { id: this.broadcast.id }, query: { step: 'builder' } })
            }
          })
        }
      }
    },

    onPrev() {
      let diff = this.difference(this.broadcast, this.constBroadcast)

      if (Object.keys(diff).length) {
        const answer = window.confirm('Are you sure you want to leave without saving?')
        if (answer) {
          this.broadcast = {
            ...this.constBroadcast
          }
          this.$emit('broadcast-changed', this.broadcast)
          this.handlePrev()
        } else {
        }
      } else {
        this.handlePrev()
      }
    },

    handlePrev() {
      if (this.currentStep > 1) {
        this.currentStep--;
        this.$nextTick(() => {
          this.$router.replace({ name: 'business.broadcasts.edit' })
        })
      }
    },

    onBroadcastChange({ broadcast, create }) {
      this.$emit('broadcast-changed', broadcast)
      this.constBroadcast = JSON.parse(JSON.stringify(broadcast))
      this.$nextTick(() => {
        if (create) {
          this.$router.push({ name: 'business.broadcasts.edit', params: { id: broadcast.id }, query: { step: 'builder' } })
        }
      })
    },

    handleOnChange(prevIndex, nextIndex) {
      this.activeTabIndex = nextIndex
    },

    onSendBroadcast() {
      this.isOpenModalConfirm = true
    },

    sendBroadcast() {
      this.broadcast.save_draft = false
      this.updateOrCreate(true)
    },

    handleOnComplete() {
    },

    chooseAlign(align) {
      this.broadcast.options.align = align
    },

    onClickTest() {
      this.testNumber = null
      this.isOpenModalTest = true
    },

    handleSaveAsDraft() {
      this.broadcast.save_draft = true
      this.updateOrCreate()
    },

    onSaveAndExit() {
      this.broadcast.save_draft = true
      this.updateOrCreate(true)
    },

    onExit() {
      this.$router.push({ name: 'business.broadcasts.index', query: { type: this.broadcast.provider } })
    },

    handleTest() {
      this.loadingSend = true;
      const param = Vue.util.extend({}, this.broadcast);
      param.number = this.testNumber
      this.$store.dispatch('broadcast/sentTest', param).then((res) => {
        this.isOpenModalTest = false;
        this.loadingSend = false;
      })
        .catch(() => {
          this.loadingSend = false;
        });
    },

    updateOrCreate(exit = false) {
      let action = 'broadcast/create'
      if (this.pageType === 'update' && this.broadcast.id) {
        action = 'broadcast/update'
      }

      this.loadingSend = true

      this.$store
        .dispatch(action, this.broadcast)
        .then(() => {
          this.loadingSend = false
          this.constBroadcast = JSON.parse(JSON.stringify(this.broadcast))
          this.$emit('broadcast-changed', this.broadcast)
          if (exit) {
            this.$router.push({ name: 'business.broadcasts.index', query: { type: this.broadcast.provider } })
          }
        })
        .catch(() => {
          this.loadingSend = false
        })
    },
  },
}
</script>
  
<style lang="scss">
.broadcast-section {
  .align-item {
    display: inline-block;
    border: 1px solid transparent;
    padding: 3px 5px 0px 5px;
    border-radius: 5px;
    cursor: pointer;

    i {
      color: rgba(0, 27, 72, 0.54);
      font-size: 20px;
    }

    &.active {
      background: #4284f4;
      color: #FFF;

      i {
        color: #FFF;
      }
    }
  }

  .align-container {
    display: flex;
    background: #FFF;
    border-radius: 5px;
    margin-left: 20px;
  }

  .wizard-wrapper.tab-fullscreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0px;
    z-index: 999;
    background: #FFF;

    .action-wrapper {
      position: fixed;
      width: 100%;
      top: 0px;
      padding: 5px 5px;
      background: #646464;
      z-index: 1;

      .btn-group {
        .btn {
          margin-right: 2px;
        }
      }
    }
  }
}
</style>